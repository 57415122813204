<template>
  <CommonPages id="blogs" classs="blogs">
    <div class="banner-row blogs">
      <div class="banner-head">
        Recommend Reading
      </div>
    </div>

    <div class="blog-list-container ">
      <div class="blog-card-row">
        <div v-for="(data, i) in card" :key="i" class="blog-card-item cursor"
          @click="goto(data.routename)">
          <img :src="data.img" class="blog-card-image" :alt="data.title">
          <div class="blog-card-content">
            <div class="blog-card-title">
              {{ data.title }}
            </div>
            <div class="blog-card-desc">
              {{ data.desc }}
            </div>
          </div>
          <div class="blog-card-btn">
            Read More <img src="@/assets/readmore.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <!-- <AdComponent
      v-if="deviceType != 'mobile' && $global.isObjectNotEmpty(adsensConfig) && adsensConfig?.home_1"
      ref="ads-type-insert-1" class="ads-insert-full pc_padding padding-horizon-40"
      :ads="adsensConfig?.home_1" :showDebug="showDebug" />

    <div class="dom-father-row ">
      <template v-for="(data,i) in dietTypeList">
        <div :key="`dishes-item-${i}`" class="dishes-display-item"
          @click="selectDiet(formatName(data.name))">
          <img v-lazy="data.imageUrl?data.imageUrl:''" class="dishes-item-img" alt="">
          <div class="dished-item-name">
            {{ data.name }}
          </div>
        </div>
        <AdComponent
          v-if="$global.isObjectNotEmpty(adsensConfig) && adsensConfig?.home_insert && shouldInsertAdDeviece('homeInsert',i) !== -1"
          :key="`ad_${i}`" :ref="'ads-recipe-insert' + i"
          class="ads-recipe-insert ads-insert-max ads-insert  "
          :ads="adsensConfig?.home_insert?.[shouldInsertAdDeviece('homeInsert',i)]"
          :showDebug="showDebug" />
      </template>
    </div> -->
  </CommonPages>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import AdComponent from '@/pages/components/AdComponent.vue'
import CommonPages from '@/pages/components/page_common.vue'
import '@/css/home.scss';

export default {
  name: 'Home',
  components: {
    AdComponent,
    CommonPages,
  },
  mixins: [common],
  data () {
    return {
      card: [
        {
          img: require('@/assets/blog-1.png'),
          title: 'Seven Powerful Ways of Thinking in the Bible',
          desc: "German writer Goethe said that all my profound thoughts come from my belief in the Bible; my moral life and literary works are all guided by the Bible. The Bible is the most reliable and powerful capital in my life, and it is really an inexhaustible treasure house. Understanding these 7 kinds of thinking in the Bible can help you embark on a path of understanding and have lively hope in life.",
          routename: 'seven-powerful-ways-of-thinking-in-the-bible',
        },
        {
          img: require('@/assets/blog-2.png'),
          title: 'Greed in the Bible',
          desc: "The concept of coveting in the Bible is highly significant for understanding its morality and ethics. Essentially, coveting is having a strong longing for what belongs to others. This simple yet profound concept, deeply rooted in Biblical teachings like the Ten Commandments, reveals much about human nature and spiritual expectations.In this article, we will take a deeper look at greed as described in the Bible.",
          routename: 'greed-in-the-bible',
        },
        {
          img: require('@/assets/blog-3.png'),
          title: 'What does the Bible say about judging others',
          desc: "In our daily lives, we often find ourselves in the position of passing judgment on others. Whether it's a quick assessment of someone's behavior, appearance, or choices, we seem to have an innate tendency to form opinions and judgments. But what does the Bible have to say about this all-too-common human trait?",
          routename: 'what-does-the-bible-say-about-judging-others',
        },
        {
          img: require('@/assets/blog-4.png'),
          title: 'Revelation from the Stories of People Who Overcame Suffering in the Bible',
          desc: "In the Bible, there are many people who have experienced suffering. They showed admirable tenacity and courage in difficult situations. With their faith and strength, they turned suffering into grace, making life and living more abundant and meaningful. Next, let us learn how they faced suffering, got out of difficulties, and made life better.",
          routename: 'revelation-from-the-stories-of-people-who-overcame-suffering-in-the-bible',
        },

      ],
      /////////列表示例
      movieList: [],
      hasNext: true,
      page: 1,
      observedAds: null
    };
  },
  computed: {
    title () {
      return `Blogs | ${this.domainConfig?.['meta']?.title || 'Default Title'}`
    },
    description () {
      return `Access the Bible anytime with ${this.domainConfig['IAmURL']}. Enjoy seamless navigation for study and reflection.`
    },
    ...mapGetters([
      "adsensConfig",
      'adPosConfig',
    ]),

  },
  metaInfo () {
    return {
      title: this.title,
      meta: [
        {
          name: 'description',
          content: this.description,
        },
        {
          property: 'og:title',
          content: this.title,
        },
        {
          property: 'og:description',
          content: this.description,
        },
      ],
    };
  },
  created () {
    //获取参数示例
    // const { paramsName } = this.$route.params;
    // console.log(`paramsName: ${paramsName}`);
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    this.loadAdSenseScript();  // 重新加载广告脚本
    if (typeof window !== 'undefined') {
      this.initAdTracking(); //客户端交互
    }
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
    if (this.checkInterval) {
      clearInterval(this.checkInterval); //客户端交互移除
    }
    if (this.visibilityChangeHandler) {
      document.removeEventListener('visibilitychange', this.visibilityChangeHandler);//客户端交互移除
    }
  },
  methods: {

    // 防抖函数 + 滚动
    debounce (func, delay) {
      let timeout;
      return function (...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    onScroll () {
      const documentHeight = document.documentElement.scrollHeight;// 获取文档的总高度
      const scrollTop = window.scrollY || document.documentElement.scrollTop; // 当前滚动的高度
      const windowHeight = window.innerHeight;// 视口的高度
      // 当滚动距离接近底部2000px时触发
      if (scrollTop + windowHeight >= documentHeight - 2000) {
        console.log('加载');
      }
    },
    //广告相关start
    shouldInsertAd (pos, index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig?.[pos].indexOf(position) //TODO: 修改adPosConfig.home的字段为你设置的

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldInsertAdDeviece (pos, index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.deviceType == 'mobile' ? this.adPosConfig?.[pos].indexOf(position) : this.adPosConfig?.[`${pos}PC`].indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    shouldDetailInsertAd (index) {
      // 判断是否应该插入广告的位置
      const position = index + 1;
      const adIndex = this.adPosConfig.detail.indexOf(position);

      /// 返回广告在 homeAD 中的索引，如果不在配置中则返回 -1
      return adIndex !== -1 ? adIndex : -1;
    },
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.observeAds()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig?.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        this.observeAds(); // 监听广告元素进入可视区域
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      // script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      script.onload = this.observeAds.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    observeAds () {
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-'))
        .flatMap(([, ref]) => ref);

      if (!this.observedAds) {
        this.observedAds = new Set();
      }

      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // 当广告元素进入可视区域时加载广告
            console.log('Ad element is in view:', entry.target);
            this.loadAdWithDelay(entry.target);
            observer.unobserve(entry.target); // 加载后停止观察该广告元素
          }
        });
      });

      adsElements.forEach(ad => {
        const adElement = ad instanceof HTMLElement ? ad : ad.$el;
        if (adElement && !this.observedAds.has(adElement)) {
          observer.observe(adElement);
          this.observedAds.add(adElement);
          console.log('Observing new ad element:', adElement);
          console.log(this.observedAds);
        }
      });

    },
    loadAdWithDelay (adElement) {
      // console.log('7897988979879');
      // console.log(adElement);
      setTimeout(() => {
        this.displayAd(adElement);
      }, 1500);
    },

    async displayAd (adElement) {
      await this.$nextTick();

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(() => this.displayAd(adElement), 500); // 延迟再次尝试
        return;
      }

      console.log('Ad element is ready for push:', adElement);
      if (adElement.offsetWidth > 0) {
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } else {
        console.log('Ad element has no size, skipping.');
      }
    },
    //广告相关end

    //跳转
    goto (routername) {
      window.location.href = `/${routername}`
    },

    //客户端交互 1121已优化版本
    initAdTracking () {
      this.activeIframe = null; // 存储最近被点击的 iframe
      this.iframes = new Map(); // 存储已添加的 iframe

      // 监听 iframe 的插入
      this.watchForIframeInsertion();

      // 设置页面可见性变化的处理
      this.setupVisibilityTracking();
    },
    watchForIframeInsertion () {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node.tagName === 'IFRAME' && node.closest('ins.adsbygoogle')) {
              this.addIframe(node);
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });
    },
    addIframe (iframeElement) {
      if (this.iframes.has(iframeElement)) {
        return; // 已经添加过，直接返回
      }

      this.iframes.set(iframeElement, {
        hasTracked: false,
        adSlot: iframeElement.closest('ins.adsbygoogle')?.dataset.adSlot || null,
      });

      // 如果还没有启动定时器，启动检查点击的定时器
      if (!this.checkInterval) {
        this.checkInterval = setInterval(this.checkIframeClick, 200);
      }
    },
    checkIframeClick () {
      const activeElement = document.activeElement;
      if (activeElement && this.iframes.has(activeElement)) {
        const iframeData = this.iframes.get(activeElement);
        if (!iframeData.hasTracked) {
          this.handleIframeClick(activeElement);
          iframeData.hasTracked = true;
          this.activeIframe = { element: activeElement, ...iframeData };
        }
      }
    },
    handleIframeClick (iframeElement) {
      console.log('Iframe 点击触发了!', iframeElement);
      const adContainer = iframeElement.closest('ins.adsbygoogle');

      if (adContainer) {
        // 获取父级 ins 元素的边界矩形
        const insRect = adContainer.getBoundingClientRect();

        // 计算父级 ins 元素相对于页面左上角的位置和高度
        const transformData = {
          insX: insRect.left,
          insY: insRect.top,
          insHeight: insRect.height,
          windowWidth: window.innerWidth,
          windowHeight: window.innerHeight,
        };

        // 调用核心功能
        this.$adClick(JSON.stringify(transformData));
        this.trackEvent('ad_iframe_click', 'click', {
          'data-ad-slot': adContainer.dataset.adSlot,
        });
      }
    },
    setupVisibilityTracking () {
      this.visibilityChangeHandler = () => {
        if (document.visibilityState === 'visible') {
          console.log('页面激活状态，返回页面');
          this.activeIframe = null; // 重置最近被点击的 iframe 记录
        } else {
          console.log('页面非激活状态');
          if (this.activeIframe) {
            // 上报逻辑
            this.trackEvent('ad_iframe_click_leave', 'click', {
              'data-ad-slot': this.activeIframe.adSlot,
            });
          }
        }
      };

      document.addEventListener('visibilitychange', this.visibilityChangeHandler);
    },
    trackEvent (eventName, eventValue, dataAttributes) {
      // 事件追踪逻辑
      console.log(`${eventName} with value ${eventValue} on`, dataAttributes);

      // 调用核心功能
      if (typeof this.$eventrack === 'function') {
        this.$eventrack(eventName, eventValue, {
          ...dataAttributes,
        });
      }
    },
    //客户端交互

  }
};
</script>
